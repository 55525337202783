import React from "react"
import Layout from "src/component/layout/layout"
import { StaticImage } from "gatsby-plugin-image"
import AiCasestudyListLatest3posts from "src/component/list/aiCasestudyListLatest3posts"
import { Seo } from "src/component/common/seo"
import { graphql, Link } from "gatsby"
import AiModelsLogo from '../../assets/img/pages/top/logo_ai_models.svg';

export default function AiModels({ data }) {
        const filteredItems = data.allMicrocmsAiServices.edges.filter(({ node }) =>
          node.category.some(cat => cat.id === "ai-models")
        )
        React.useEffect(() => {
        let js_scroll = document.getElementsByClassName('js-scroll');
        const scroll = () => {
            for (let i = 0; i < js_scroll.length; i++) {
                if ((window.pageYOffset + (window.innerHeight / 1.5)) > (window.pageYOffset + js_scroll[i].getBoundingClientRect().top)) {
                    js_scroll[i].classList.add('is-active');
                }
            }
        };
        scroll();
        window.addEventListener('scroll', () => {
            scroll();
        })
    }, [])
    return (
        <>
            <Layout>
              <div className="ai-series-wrap">
                <div className="ai-series">
                    <div className="ai-series__inner c-inner-primary">
                            <h1 className="ai-series__title">
                                <img src={AiModelsLogo} alt="AI Models" className="title-logo" />
                            </h1>
                            <div className="ai-series__item__catchcopy title-bold-catchcopy c-pc js-scroll">
                                <div><div><span>高精度の予測や最適化を実現するオーダーメイド型AIサービス</span></div></div>
                            </div>
                            <div className="ai-series__item__catchcopy title-bold-catchcopy c-sp js-scroll">
                                <div><div><span>高精度の予測や最適化を実現する</span></div></div>
                                <div><div><span>オーダーメイド型AIサービス</span></div></div>
                        </div>
                        
                        <div className="ai-series__policy">
                            <p className="ai-series__lead">
                                従来のツールでは対応が難しかった 複雑なビジネス課題に対し、オーダーメイドのAIモデルを開発。
                                <br />
                                予測と最適化を組み合わせることで、業務プロセスの高度化と効率化を実現します。</p>
                            <p className="ai-series__lead">
                                人流データ・衛星データ・IoTデータなど、多様なデータソースを活用し、
                                <br />
                                ビジネスの現場で活用できる精度と信頼性を備えたAIモデルを提供します。
                                <br />
                                また、AIの意思決定プロセスを透明化し、企業が求める説明責任を果たせる仕組みを構築することが可能です。
                            </p>
                       </div>
                    </div>
                </div>

               

                <section className="ai-series-features">
                    <div className="ai-series-features__inner c-inner-primary">
                        <ul>
                            <li className="ai-series-features__feature">
                                <div className="ai-series-features__feature__title">POINT 01</div>
                                <h3>予測と最適化を融合したAI活用でビジネスを変える</h3>
                                <p>先進的なAI技術と複雑な数理モデルの統合により、ビジネスに競争力と革新をもたらします。
                                    <br />
                                    <br />
                                    たとえば、需要予測と配送ルート最適化を統合されたソリューションとして実現することにより、業務の自動化・効率化とコスト削減を同時に達成可能です。
                                </p>
                            </li>
                            <li className="ai-series-features__feature">
                                <div className="ai-series-features__feature__title">POINT 02</div>
                                <h3>本番運用を見据えた信頼性の高いモデル構築</h3>
                                <p>POC（概念実証）から本番運用への移行では、ノイズの多い現実のデータやトレンドの変動に対応する必要があります。
                                    <br />
                                    <br />
                                   Airlake AI modelsは、データの季節性や外部要因を考慮し、本番環境でも高い精度を維持する予測モデルを開発可能です。丁寧なプロセスと豊富なノウハウで、社内への説明責任を果たしやすい信頼性の高いモデルを提供します。
                                </p>
                            </li>
                            <li className="ai-series-features__feature">
                                <div className="ai-series-features__feature__title">POINT 03</div>
                                <h3>内製が困難である高度なアルゴリズム開発</h3>
                                <p>企業によって異なる様々なビジネス課題に合わせた高度な予測・最適化のアルゴリズムを開発し、内製や一般的なツールでは解決が難しい領域に対応可能です。
                                    <br />
                                    <br />
                                    独自の技術力と豊富な知見により、大規模プロジェクトや新しい技術への挑戦を力強く支援し、企業の課題解決に貢献します。
                                </p>
                            </li>
                        </ul>
                     
                    </div>
                </section>

                {/* 機能追加するcode DON'T delete */}
                {/* <section className="ai-functions">
                    <div className="ai-functions__inner c-inner-primary">
                        <div className="ai-functions__title c-title01">
                            <h2 className="ai-functions__title__text c-title01__text">機能紹介</h2>
                        </div>

                     <ul className="ai-functions__list">
                        {filteredItems.map(({ node }) => (
                            <li key={node.id}>
                                <div className="ai-functions__list__image">
                                    <div className="ai-functions__list__image__inner">
                                        <img src={node.thumnail.url} alt={node.title} width={node.thumnail.width} height={node.thumnail.height} />
                                    </div>
                                </div>
                                <div 
                                    className={`ai-functions__list__text ${node.button && node.link ? 'has-button' : 'no-button'}`} 
                                >
                                    <h3 className="ai-functions__list__title">
                                        <span className="is-title">{node.title}</span>
                                    </h3>
                                    <p className="ai-functions__list__lead">{node.content}</p>
                                </div>
                                {node.button && node.link && (
                                    <div className="ai-functions__list__button">
                                        <div className="ai-functions__list__button__inner">
                                            <Button external path={node.link} className="c-button01" label="詳しく見る" />
                                        </div>
                                    </div>
                                )}
                            </li>
                          ))}
                      </ul>

                    </div>
                </section> */}
                

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                            {/* <Button path="/search/?pc=case_study" className="p-top-casestudy__title__button c-title01__button c-button01" label="すべての事例を見る" /> */}
                        </div>
                         <AiCasestudyListLatest3posts />
                    </div>
                </section>
              </div>
            </Layout>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="AI MODELS"
            description=""
            pathname=""
        />
    )
}

export const query = graphql`
    query {
        allMicrocmsAiServices {
            edges {
                node {
                    id
                    createdAt
                    updatedAt
                    title
                    content
                    thumnail {
                        url
                        width
                        height
                    }
                    category {
                        id
                        name
                    }
                    button
                    link
                }
            }
        }
    }
`